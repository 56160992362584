.retail-slider-container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

}

.retail-slider-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/img/retail-slider/retail-background.jpg);
  background-position: center;
  background-size: cover;
}

.slide {
  // background: #252B63;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    padding: 1rem;
    padding-bottom: 0;
    font-family: $font-semibold;
    font-size: 24px;
    color: white;
    line-height: 1.2;
    text-align: center;
  }
}

.rocketman-slide {
  p {
    font-size: 44px;
    font-family: $font-bold;
  }

  .rocketman-slider-gif {
    height: 400px;

    @media (min-width: 1920px) {
      height: 600px;
    }
  }
}

.slide-loading {
  .loading-screen-text {
    font-size: 28px;
  }
}

.game-explanation {
  p {
    width: 90%;
  }
}

.multiplier-history {
  p {
    margin-bottom: 2rem;
  }
}

.countdown-slider {
  p {
    margin-bottom: 0;
  }

  .countdown-counter {
    font-family: $font-bold;
    color: white;
    font-size: 360px !important;
    line-height: 0.9;
    animation: pulse-small 800ms linear;

    @media (min-width: 1920px) {
      font-size: 520px !important;
      line-height: 1;
    }

    &.pulse-strong {
      animation: pulse-strong 700ms linear;
    }
  }
}

p {
  &.slider-text-big {
    font-size: 66px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);

    @media (min-width: 1920px) {
      font-size: 78px;
    }

    strong {
      color: #3DCCFF;
      font-family: $font-bold;
      font-size: 70px;

      @media (min-width: 1920px) {
        font-size: 80px;
      }
    }
  }
}

.max-multiplier {
  p {
    &.slider-text-big {
      font-size: 120px;
  
      @media (min-width: 1920px) {
        font-size: 160px;
      }
  
      strong {
        font-size: 140px;
  
        @media (min-width: 1920px) {
          font-size: 180px;
        }
      }
    }
  }
}

.bonus-launch {
  p {
    &.slider-text-big {
      font-size: 96px;
  
      @media (min-width: 1920px) {
        font-size: 134px;
      }
  
      strong {
        font-size: 116px;
  
        @media (min-width: 1920px) {
          font-size: 156px;
        }
      }
    }
  }
}

.slide {
  &.multibet {
    p {
      &.slider-text-big {
        font-size: 48px;
    
        @media (min-width: 1920px) {
          font-size: 60px;
        }
    
        strong {
          font-size: 52px;
          color: #f7fd04;
    
          @media (min-width: 1920px) {
            font-size: 62px;
          }
        }
      }
      &.slider-text-small {
        font-size: 34px;
        font-family: $font-regular;
    
        @media (min-width: 1920px) {
          font-size: 50px;
        }
      }
    }
  }
}

.retail-planet-satelite-group-1 {
  position: absolute;
  left: 15px;
  top: -10px;
  height: 80px;

  @media (min-width: 1920px) {
    left: 40px;
    height: 150px;
  }
}

.retail-planet-1 {
  position: absolute;
  top: 30px;
  left: 30px;

  @media (min-width: 1920px) {
    top: 70px;
    left: 50px;
  }

  .retail-planet-main {
    -webkit-animation: rotating-test 220s linear infinite;

    height: 200px;

    @media (min-width: 1920px) {
      height: 300px;
    }
  }

  .retail-planet-satelite-1 {
    position: absolute;
    right: -44px;
    bottom: 120px;
    -webkit-animation: floating-1 8s linear infinite;

    height: 24px;

    @media (min-width: 1920px) {
      height: 32px;
    }
  }

  .retail-planet-satelite-2 {
    position: absolute;
    left: -10px;
    bottom: -10px;
    -webkit-animation: floating-2 10s linear infinite;

    height: 32px;

    @media (min-width: 1920px) {
      height: 42px;
    }
  }
}

.retail-planet-satelite-group-2 {
  position: absolute;
  right: 15px;
  top: -10px;
  height: 80px;

  @media (min-width: 1920px) {
    right: 40px;
    height: 150px;
  }
}

.retail-planet-2 {
  position: absolute;
  top: 30px;
  right: 30px;

  .retail-planet-main {
    height: 200px;

    @media (min-width: 1920px) {
      height: 300px;
    }

    -webkit-animation: rotating-reverse 220s linear infinite;
  }

  .retail-planet-satelite-1 {
    position: absolute;
    left: -44px;
    bottom: 120px;
    -webkit-animation: floating-2 8s linear infinite;
    height: 24px;

    @media (min-width: 1920px) {
      height: 32px;
    }
  }

  .retail-planet-satelite-2 {
    position: absolute;
    right: -10px;
    bottom: -10px;
    -webkit-animation: floating-1 10s linear infinite;
    height: 32px;

    @media (min-width: 1920px) {
      height: 42px;
    }
  }
}

@keyframes rotating-test {
  from {
    transform: rotate(0deg) translateX(0);
  }

  to {
    transform: rotate(360deg) translateX(-50%);
  }
}

@keyframes rotating-reverse {
  from {
    transform: rotate(360deg) translateX(0);
  }

  to {
    transform: rotate(0deg) translateX(-50%);
  }
}

@-webkit-keyframes rotating-test {
  from {
    transform: rotate(0deg) translateX(0);
  }

  to {
    transform: rotate(360deg) translateX(-50%);
  }
}

@-webkit-keyframes rotating-reverse {
  from {
    transform: rotate(360deg) translateX(0);
  }

  to {
    transform: rotate(0deg) translateX(-50%);
  }
}

@keyframes floating-1 {
  from {
    transform: translate(0, 0px);
  }

  55% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating-2 {
  from {
    transform: translate(0, 0px);
  }

  55% {
    transform: translate(0, -15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes pulse-small {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse-strong {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.retail-bonus-round {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 15px;
  font-size: 32px;
  line-height: 1.2;
  color: #f7fd04;
  z-index: 1;
  font-family: $font-bold;
  animation: pulse-small 1500ms linear infinite;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);

  span {
    &.small {
      font-family: $font-semibold;
      font-size: 28px;
    }
  }
}
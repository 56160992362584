.header-bonus-games-desktop {
  padding: 0;
  margin-right: 0.5rem;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }

  .header-bonus-games-item {
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 0.5rem;
  }
}

.desktop-username {
  position: relative;
  font-family: $font-semibold;
  font-size: 16px;
  color: white;
  margin: 0 0.5rem;
  margin-right: 1rem;
}

.header-avatar-mobile {
  display: flex;

  @media (min-width: 1024px) {
    display: none;
  }
}

.header-avatar-desktop {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }
}

.header-avatar-dropdown-desktop {
  right: 31px;
}

.header-avatar-dropdown-menu {
  width: 350px;
  padding: 0;

  .page-holder.with-provably {
    min-height: auto;
  }

  .menu-item {
    padding: 1rem 0;
    background-color: transparent;
    border-bottom: 1px solid #454b7a;
    border-radius: 0;
    margin-bottom: 0;

    .menu-image-holder {
      border-right: none;
    }

    .menu-item-title {
      margin-left: 0;
    }
  }
}

.header-item-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: $white-opacity-10;
  border-radius: 5px;
  @include transition(0.3s ease-in-out all);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      background-color: transparent;
    }
  }

  &:focus {
    border: 1px solid $yellow-100;
  }

  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-100;
  }

  &.active {
    background-color: #ffbf1a;

    svg {
      @include transform(rotate(-180deg));
    }
  }
}

.desktop-page-holder {
  @media (min-width: 1024px) {
    position: relative;
    border-radius: 1rem;
    background: $blue-400;
    padding: 3.5rem 5.625rem;
    margin-bottom: 1rem;
  }
}

.desktop-page-back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.desktop-page-back-button {
  width: 130px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-family: $font-semibold;
  font-size: 14px;
  line-height: 14px;
  color: white;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 0 12px;
  border: 1px solid transparent;
  @include transition(0.3s ease-in-out all);
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      background-color: transparent;
    }
  }

  &:focus {
    border: 1px solid $yellow-100;
  }

  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-100;
  }

  svg {
    margin-bottom: -1px;
  }
}

.desktop-separator-line {
  display: none;
  height: 1px;
  background-color: #454b7a;
  opacity: 0.4;
  border: none;
  margin: 1.5rem 0;

  @media (min-width: 1024px) {
    display: block;
  }
}

.desktop-container {
  position: relative;
  display: flex;
  padding: 15px 0;
  overflow: hidden;
  height: calc(100vh - 84px);

  &.retail {
    height: 100vh;
    padding: 0;
  }
}

.container-retail {
  max-width: 100% !important;
  padding: 0;
}

.desktop-background {
  display: none;
  position: absolute;
  top: 84px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #0e1340;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 1024px) {
    display: block;
    background-image: url(/img/bg-1080.jpg);
  }

  @media (min-width: 1920px) {
    background-image: url(/img/bg-2560.jpg);
  }

  &.retail {
    top: 0;
    background-image: none;
  }
}

.desktop-section-title {
  padding: 1rem;
  padding-bottom: 0;
  font-family: $font-bold;
  font-size: 18px;
  color: white;
  display: flex;
  justify-content: space-between;
}

.desktop-left {
  background: $blue-400;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  height: 100%;
  width: auto;

  @media (min-width: 1024px) {
    width: 340px;
  }

  @media (min-width: 1200px) {
    width: 375px;
  }

  &.retail {
    width: 400px;

    @media (min-width: 1920px) {
      width: 500px;
    }

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .desktop-section-title {
      font-size: 24px;

      @media (min-width: 1920px) {
        font-size: 26px;
      }

      justify-content: flex-start;
      align-items: center;

      .pod-tabs-nav-badge {
        font-size: 20px;
        line-height: 24px;
        padding: 0 1rem;
        border-radius: 1rem;
        margin-left: 1rem;
      }
    }

    .table-header {
      height: 40px;

      @media (min-width: 1920px) {
      height: 60px;
      }

      th {
        font-size: 16px;

        @media (min-width: 1920px) {
        font-size: 18px;
        }
        width: 22.5%;

        &:first-child {
          width: 35%;
        }
      }
    }

    .all-bets-tr {

      td {
        font-size: 14px;
        @media (min-width: 1920px) {
        font-size: 16px;
        }
        line-height: 1.3;
        padding: 1rem 0;

        &:last-child {
          padding-right: 0.25rem;
        }

        .all-bets-tr-username {
          max-width: 80%;
        }
      }
    }
  }
}

.retail-round-id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #070a2a;
  position: absolute;
  top: 70px;
  right: 15px;
  color: white;
  z-index: 1;
  padding: .5rem 1rem;
  border-radius: 5px;
  font-family: $font-bold;
  font-size: 22px;

  @media (min-width: 1920px) {
    font-size: 26px;
    top: 80px;
  }
}

.retail-round-id-title {
  opacity: 0.5;
}

.desktop-left-height {
  height: calc(100% - 43px);
}

.desktop-betting-tabs {
  @media (min-width: 1024px) {
    height: calc(100vh - 272px) !important;
    // height: calc(100% - 45px);
    overflow-y: auto;

    .simplebar-scrollbar {
      right: 4px;
      left: 0;
    }

    .simplebar-track.simplebar-vertical {
      top: -2px;
      bottom: 2px;
      width: 12px;
      border-radius: 6px;
    }

    .simplebar-scrollbar:before {
      background: #636479;
    }
  }

  &.retail {
    height: 100% !important;
  }
}

.desktop-chat {
  min-height: 100%;
  // scroll-behavior: smooth;

  .simplebar-scrollbar {
    right: 4px;
    left: 0;
  }

  .simplebar-track.simplebar-vertical {
    top: -2px;
    bottom: 2px;
    width: 12px;
    border-radius: 6px;
  }

  .simplebar-scrollbar:before {
    background: #636479;
  }

  @media (min-width: 1024px) {
    min-height: auto;
    height: calc(100vh - 245px) !important;
    overflow-y: auto;
  }
}

.desktop-left-bottom-background,
.desktop-right-bottom-background {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(360deg, #070a2a 0%, rgba(7, 10, 42, 0) 109%);
  pointer-events: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.desktop-left-bottom-background {
  border-bottom-left-radius: 1rem;
}

.desktop-right-bottom-background {
  border-bottom-right-radius: 1rem;
}

.desktop-middle {
  position: relative;
  flex: 1;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  @include transition(0.3s all ease-in-out);

  &.retail {
    border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  }

  &.active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .play-now-page-holder {
    height: 100%;
  }
}

.chat-expand-button {
  position: absolute;
  top: 64px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #070a2a;
  border: none;
  padding: 0;
  padding-top: 2px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-right {
  z-index: 4;
  position: relative;
  background: $blue-400;
  width: 0;
  height: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  @include transition(0.3s width ease-in-out);
  overflow: hidden;

  .page-holder.full-height {
    height: calc(100vh - 157px);
  }

  .desktop-right-content {
    opacity: 0;
  }

  &.opened {
    width: auto;

    @media (min-width: 1024px) {
      width: 300px;
    }

    @media (min-width: 1200px) {
      width: 375px;
    }

    .desktop-right-content {
      @include transition(1.5s opacity ease-in);
      opacity: 1;
    }
  }
}

.desktop-chat-online {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  p {
    font-family: $font-semibold;
    font-size: 14px;
    color: white;
  }

  span {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #a7e623;
    margin: 0 4px;
    margin-bottom: -1px;
  }
}

.provably-fair-explainer-desktop {
  @media (min-width: 1024px) {
    display: flex;

    .how-to-check-image-holder {
      width: 360px;
      margin-right: 1rem;
    }

    .how-to-check-text-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }
  }
}

.button-footer {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 5.625rem;
    display: flex;
    background: rgba(3, 5, 32, 0.9);

    .primary-button {
      width: 120px;
      margin-left: 1rem;
    }
  }
}
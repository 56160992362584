.jackpots-header-holder {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 10;
  
  &.top-0 {
    top: 0;
    opacity: 1!important;
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
  }
}

.jackpots-header {
  display: flex;
  align-items: center;
  height: 40px;
  background: rgba(7, 10, 42, 0.6);

  &.retail {
    pointer-events:none;
    height: 62px;
    @media (min-width: 1920px) {
    height: 72px;
    }

    .container {
      max-width: 100% !important;
    }

    .jackpot-holder {
      height: 52px;
      @media (min-width: 1920px) {
      height: 56px;
      }
    }
    .jackpot-cash-holder, .odometer-value, .odometer-formatting-mark {
      font-size: 46px;

    }
    .jackpot-currency {
      font-size: 20px;
      margin-top: 3px;
      margin-left: 3px;
    }
    .jackpot-title {
      font-size: 14px;

      @media (min-width: 1920px) {
      font-size: 16px;
      }
    }
  }
}

.jackpot-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2rem;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

  &:hover {
    background: linear-gradient(
      353.59deg,
      rgba(59, 196, 242, 0.2) -28.26%,
      rgba(59, 196, 242, 0.2) 61.16%
    );
  }

  @media (min-width: 1024px) {
    max-width: initial;
  }

  $border: 1px;
  color: #fff;
  background: rgba(7, 10, 42, 0.9);
  background-clip: padding-box;
  border-radius: 2rem;

  &.r7css {
    .odometer-value,
    .odometer-formatting-mark,
    .jackpot-cash-holder {
      font-size: 16px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: linear-gradient(
      45deg,
      rgba(59, 196, 242, 1) 0%,
      transparent 35%,
      transparent 100%
    );
    // animation: rotate 4s linear infinite;
  }

  &.jackpot-active {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 32px;
    border-radius: 32px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      z-index: -2;
      left: 0;
      top: 50%;
      margin-top: -50%;
      width: 100%;
      padding-bottom: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      // background-size: 50% 50%, 50% 50%;
      // background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-position: top;
      // background-image: linear-gradient(#399953, #399953),
      //   linear-gradient(transparent, transparent), linear-gradient(#399953, #399953),
      //   linear-gradient(transparent, transparent), linear-gradient(#399953, #399953),
      //   linear-gradient(transparent, transparent);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0) 10%,
        rgba(57, 153, 83, 1) 15%,
        rgba(57, 153, 83, 1) 20%,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0) 30%,
        rgba(57, 153, 83, 1) 35%,
        rgba(57, 153, 83, 1) 40%,
        rgba(255, 255, 255, 0) 45%,
        rgba(255, 255, 255, 0) 50%,
        rgba(57, 153, 83, 1) 55%,
        rgba(57, 153, 83, 1) 60%,
        rgba(255, 255, 255, 0) 65%,
        rgba(255, 255, 255, 0) 70%,
        rgba(57, 153, 83, 1) 75%,
        rgba(57, 153, 83, 1) 80%,
        rgba(255, 255, 255, 0) 85%,
        rgba(255, 255, 255, 0) 90%,
        rgba(255, 255, 255, 0.0032387955182072714) 95%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: rotate 5s linear infinite;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 2px;
      top: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: rgba(7, 10, 42, 1);
      border-radius: 28px;
    }
  }

  .odometer-value,
  .odometer-formatting-mark {
    font-family: $font-bold;
    font-size: 14px;
    color: white;
    display: inline-block;
    vertical-align: middle;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.jackpot-cash-holder {
  display: flex;
  align-items: center;
  font-family: $font-bold;
  font-size: 14px;
  color: white;
  line-height: 1.1;
  padding-bottom: 2px;
}

.jackpot-title {
  font-family: $font-bold;
  font-size: 8px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  margin-top: -3px;
  margin-bottom: -5px;

  @media (min-width: 1024px) {
    margin-bottom: -2px;
  }

  &.r7css {
    font-size: 10px;
  }
}

.jackpot-title-retail {
  font-family: $font-bold;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 16px;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.jackpot-info-holder {
  position: absolute;
  background-color: #070a2a;
  top: 50px;
  left: 0.25rem;
  border-radius: 5px;
  min-width: 330px;
  display: block;

  &.hide {
    display: none;
  }

  &.rocketpot-info-holder {
    left: 50%;

    transform: translate(-50%, 0);

    &.no-jackpot {
      left: 0.25rem;
      transform: none;
    }
    &.no-boosterpot {
      left: initial;
      right: 0.25rem;
      transform: none;
    }
  }

  &.boosterpot-info-holder {
    left: initial;
    right: 0.25rem;
  }

  @media (min-width: 1024px) {
    min-width: 324px;
  }

  @media (min-width: 1200px) {
    min-width: 360px;
  }
  .my-bets-table {
    margin-bottom: 0;
  }

  .my-bets-table .table-header th:first-child {
    width: 20%;
    border-top-left-radius: 0;
  }
  .my-bets-table .table-header th:last-child {
    width: 40%;
    text-align: left;
    border-top-right-radius: 0;
  }
  .my-bets-table .table-header th:nth-last-child(2) {
    text-align: left;
  }
  .my-bets-table .table-header th {
    width: 20%;
  }
  .my-bets-table .all-bets-tr td:first-child {
    width: 20%;
  }
  .my-bets-table .all-bets-tr td:last-child {
    width: 40%;
    text-align: left;
  }
  .my-bets-table .all-bets-tr td:nth-last-child(2) {
    text-align: left;
  }
}

.jackpot-info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 1rem;
  font-family: $font-bold;
  font-size: 14px;
  color: white;

  .bet-info-close {
    margin: 0;
  }
}

.jackpot-currency {
  font-size: 5px;
  margin-top: 3px;
  margin-left: 1px;
  margin-left: 0px;
  
  @media (min-width: 350px) {
    font-size: 10px;
    margin-left: 1px;
  }
  @media (min-width: 1024px) {
    font-size: 12px;
    margin-top: 2px;
    margin-left: 1px;
  }

  &.r7css {
    font-size: 12px;
  }
}
